/**
 *
 * @prettier
 */

import { h, Fragment } from 'preact';
/** @jsx h */
import classNames from 'classnames';
import useIsMobile from './isMobile';
import { useDataBasic, useDataFilters } from './dataGraphQL';
import Bar from './Bar';
import CollectionsList from './CollectionsList';
import ProductsGrid from './ProductsGrid';
import ProductSlider from './ProductSlider';
import { useGtmTrackCollectionView } from './gtm';
import { useFilters, Filters } from './filters'; 
import { ActiveFilter } from './ActiveFilter';
import Cookie from 'js-cookie';
import OtherCollection from './OtherCollections';
import lodash from 'lodash-es'

const Page = ({
	shopCurrency,
	collectionHandle,
	collectionTitle,
	hasSubCollections,
	expandFirstSubCollection,
	collections,
	SubCollectionHeading,
	isShopAll,
  hasProducts,
	filters,
	productNameHeadingLevel,
	superPlp,
	bannerImages,
	shopAllText
}) => {
	const isMobile = useIsMobile();
	const collectionsBasicData = useDataBasic(collections);
  const isMen = collectionHandle.includes('men') && !collectionHandle.includes('women');
  const isWomen = collectionHandle.includes('women');

	// const currentDebugVWOVariation = Cookie.get('debug_vis_opt_exp_124_combi') || 0;
	const currentLiveVWOVariation = Cookie.get('_vis_opt_exp_124_combi') || 0;

	const isTesting =  currentLiveVWOVariation;
	useGtmTrackCollectionView({ shopCurrency, collectionTitle, collections, collectionsBasicData });
	const hasFilters = Boolean(filters);
	const filtersData = useDataFilters({ hasFilters, collections });
  const collectionListData = window.GLOBAL && window.GLOBAL.collectionList ? JSON.parse(window.GLOBAL.collectionList) : ''
	const {
		activeFilters,
		filtersAvailability,
		setFilter,
		clearFilters,
		productsVisibility,
	} = useFilters({
		filters,
		dataBasic: collectionsBasicData,
		dataFilters: filtersData,
	});
	// console.log(filtersAvailability)
	const hasFiltersLoaded = hasFilters && filtersData.status === 'LOADED';
	// const hasDesktopFilterLoaded = hasDesktopFilter && filtersData.status === 'LOADED';
	// console.log(filters)
	return (
		<div class="c-collectionPage__dynamic">
			<div class="c-collectionPage__heroImageWrapper">
				{bannerImages != null && bannerImages.desktopHeroImg ? 
					<Fragment> 
					<img class="u-hideMobileMaxDown" src={bannerImages.desktopHeroImg} />
					<img class="u-showMobile" src={bannerImages.mobileHeroImg} />

          <div class="c-collectionPage__heroContent">
            {isShopAll ? (
              <h1 class="c-collecctionPage__heroTitle">
                {shopAllText}
              </h1>
            ) : (
              <Fragment>
                {(isMen || isWomen) && (
                  <div class="c-collectionPage__heroHeadline">
                    {isMen && 'Men:'}
                    {isWomen && 'Women:'}
                  </div>
                )}

                <div class="c-collecctionPage__heroTitle">
                  {collectionTitle}
                </div>
              </Fragment>
            )}
          </div>
					</Fragment>
				: null }
			</div>

			<div class="c-collectionPage__barWrapper">
				<Bar collectionHandle={collectionHandle} />
			</div>
			<div class="c-collectionPage__collectionsListWrapper">
        {collectionListData &&
          collectionListData.map((item, index) => {
            return (
              <Fragment>
                <div className={'c-collectionPage__collectionsList-' + item.name}>
                  <CollectionsList
                    collectionHandle={collectionHandle}
                    superPlp={superPlp}
                    data={item}
                  />
                </div>
              </Fragment>
            )
          })}
			</div>

			{hasFilters && isMobile && (
				<div class="c-collectionPage__filtersWrapper">
					<Filters
						filters={filters}
						filtersAvailability={filtersAvailability}
						activeFilters={activeFilters}
						setFilter={setFilter}
						clearFilters={clearFilters}
					/>
				</div>
			)}

			<div class={`c-collectionPage__main ${isShopAll && hasSubCollections && hasProducts ? 'c-collectionPage__main--shop-all' : ''}`}>
				{!isMobile && isTesting == '2'&&
					<div class="">
						<ActiveFilter 
							clearFilters={clearFilters} 
							activeFilters={activeFilters} 
							setFilter={setFilter}
							isDesktop={true}
						/>
					</div>
				}

				{isShopAll && <h1 class="c-collectionPage__heading-shop-all">{shopAllText}</h1>}

				{hasFilters && !isMobile && isTesting == '2' && (
					<div class={`c-collectionPage__filtersWrapper c-collectionPage__filtersWrapper--desktop ${!isShopAll ? 'desktop-margin-bottom' : ''}`}>
						<Filters
							filters={filters}
							filtersAvailability={filtersAvailability}
							activeFilters={activeFilters}
							setFilter={setFilter}
							clearFilters={clearFilters}
							isDesktop={true}
						/>
					</div>
					)
				}
				<div class="c-collectionPage__collections">
					{collections.map(([handle], index) => {
						const collection = collectionsBasicData[handle];
						let bannerItems = index === 0 ? 8 : (expandFirstSubCollection ? 8 : 4);
						const hideBanner = handle.includes('backpack') || handle.includes('t-shirt')
						const plpEcoBanner = window.GLOBAL && window.GLOBAL.plpEcoBanner
						const hasEcoBanner = plpEcoBanner && index === 0 && !hideBanner
						if (hasEcoBanner) {
							bannerItems = index === 0 ? 7 : (expandFirstSubCollection ? 7 : 3);
						}
            if (isMobile) {
              if (index === 0) {
                bannerItems = 4
              } else {
                bannerItems = expandFirstSubCollection ? 4 : 2;
              }
            }
						const hasBanner =
							hasSubCollections && collection.size > (bannerItems + (isMobile ? 2 : 4)) && !(expandFirstSubCollection && index === 0);
						const collectionVisibility = productsVisibility[handle];
						const display = hasFiltersLoaded && !collectionVisibility ? 'none' : '';
            const anchorLink = lodash.kebabCase(collection.name);

						return (
							<div class="c-collectionPage__collection" style={{ display }}>
								<div class="c-collectionPage__collection-heading">
									<SubCollectionHeading isMainHeading={!isShopAll && index === 0}>
                    <span class="c-collectionPage__collection-heading-anchor" id={anchorLink}></span>
                    <a class="c-collectionPage__collection-heading-link" href={`#${anchorLink}`}>
                      {collection.name}
                    </a>
                  </SubCollectionHeading>
								</div>

								<div class="c-collectionPage__collection-products">
									<ProductsGrid
										collection={collection}
										hasBanner={hasBanner}
										bannerItems={bannerItems}
										collectionHandle={collectionHandle}
										productsVisibility={
											hasFiltersLoaded && collectionVisibility
										}
										productNameHeadingLevel={productNameHeadingLevel}
										superPlp={superPlp}
										plpEcoBanner={plpEcoBanner}
										hasEcoBanner={hasEcoBanner}
										filterApplied={activeFilters && Object.keys(activeFilters).length > 0}
                    isMobile={isMobile}
									/>
								</div>
							</div>
						);
					})}
				</div>
				{superPlp == "true"? 
				<Fragment>
          {bannerImages?.title && (
            <div class="c-collectionPage__heading">
              {bannerImages.title}
            </div>
          )}
					{collectionsBasicData[collectionHandle] && (
            <ProductSlider
              collections={collectionsBasicData[collectionHandle]}
              productNameHeadingLevel = {productNameHeadingLevel}
              collectionHandle={collectionHandle}
              superPlp={superPlp}
              isMobile={isMobile}
            />
          )}
					{/* <OtherCollection
						collections={collectionsBasicData[collectionHandle]}
						productNameHeadingLevel = {productNameHeadingLevel}
						collectionHandle={collectionHandle}
						superPlp={superPlp}
					/> */}
				</Fragment>
				:''}
			</div>
		</div>
	);
};

export default Page;